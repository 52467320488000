@mixin photo-gallery {
    .field__items > .field__item {
        margin-bottom: 20px;
    }
    // Photo galleries with only 3 photos are special :)
    .field__items > .field__item:not(:first-child) {
        display: inline-block;
        margin-right: 20px;
    }
    .teasers {
        display: flex;
        justify-content: flex-start;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 5px;
        .field__item {
            flex: 0 0 auto;
            padding-right: 10px;
        }
    }
    .teaser-images {
        display: flex;
        justify-content: flex-start;
        position: relative;
        overflow: visible;
    }
    .left,
    .right {
        width: 42px;
        height: 50px;
        font-size: 48px;
        transition: 0.3s ease all;
        position: relative;
        padding: 10px;
        top: 25px;
        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }
    .left {
        margin-right: 0px;
        left: 0;
        padding-left: 0;
        &:hover {
            left: -10px;
        }
    }
    .right {
        margin-left: 0px;
        padding-right: 0;
        right: 0;
        &:hover {
            right: -10px;
        }
    }
}
