#block-views-feedback-block,
.view-my-feedback,
.view-place-feedback  {
    .views-row {
        border-bottom: 1px solid $grey-light;
        margin: 20px 0;
        padding: 20px 0;
        overflow: auto;
        .views-field-field-image {
            float: left;
            margin: 0 20px 20px 0;
        }
        .views-field-field-pet2,
        .views-field-title,
        .views-field-field-new-owner-2 {
            .views-label,
            .field-content {
                display: inline-block;
            }
            a {
                font-weight: 600;
            }
        }
        @include breakpoint($tablet) {
            .views-field-field-rating,
            .views-field-changed,
            .views-field-name,
            .views-field-created,
            .views-field-edit-node,
            .views-field-comments-link {
                float: right;
                clear: right;
            }
        }
        .views-field-edit-node,
        .views-field-comments-link {
            margin: 10px 0;
            .field-content a {
                @include button--small;
            }
        }
        .views-field-comments-link {
            .links--inline li {
                margin: 0;
                float: right;
            }
        }
        .views-field-comment-body {
            margin-left: 40px;
            margin-top: 20px;
            clear: both;
            .views-label {
                font-weight: 600;
            }
        }
    }
}



.fivestar-user-stars,
.views-field-field-rating {
    margin: 10px 0;
    .form-item {
        margin: 0;
    }
    .fivestar-outline .fivestar-widget-static .star,
    .fivestar-widget-static .star   {
        height: 25px;
        width: 25px;
        background-image: none;
        padding: 1px;
        margin: 0 3px 0 0;
        span {
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
        .on {
            background-image: url('../images/paw-filled.png');
            background-position: center;
            background-size: 100%;
        }
        &.on {
            background-image: url('../images/paw-filled.png');
            background-position: center;
            background-size: 100%;
        }
        .off {
            background-image: url('../images/paw-empty.png');
            background-position: center;
            background-size: 100%;
        }
    }
    .fivestar-widget .star   {
        height: 25px;
        width: 25px;
        background-image: none;
        padding: 1px;
        margin: 0 3px 0 0;
        span {
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.on {
            background-image: url('../images/paw-filled.png');
            background-position: center;
            background-size: 100%;
            a {
                background-image: none;
            }
        }
    }
}


.node-feedback-form {
    .field-name-body {
        fieldset.filter-wrapper {
            display: none;
        }
    }
    .fivestar-widget .star  {
        height: 25px;
        width: 25px;
        background-image: none;
        padding: 1px;
        margin: 0 3px 0 0;
        a {
            background-image: url('../images/paw-empty.png');
            background-position: center;
            background-size: 100%;
        }
        &.hover a {
            background-image: url('../images/paw-filled.png');
        }
    }
}

.feedback-response-form {
    .node--feedback--full {
        .field--name-body .field__item {
            @include blockquote;
        }
    }
    .comment-form {
        margin-top: 40px;
    }
}

.node--feedback--full {
    .field--name-body .field__item {
        margin: 40px 0;
    }
    .fivestar-summary-average-count {
        display: none;
    }
}
