
.node--full {
    h1.page-title {
        margin-top: 45px;
        border-bottom: 1px solid $grey-light;
        padding-bottom: $spacing;
        &.hide {
            display: none;
        }
    }
    .field--label-above {
        p:first-child {
            margin-top: 0.5em;
        }
    }
}

h1.page-title {
    margin-top: 45px;
    border-bottom: 1px solid $grey-light;
    padding-bottom: $spacing;
    &.hide {
        display: none;
    }
}

.field--type-image {
    a {
        line-height: 0;
        text-decoration: none;
    }
}

.field.field--name-title a,
.field.field--name-name a {
    text-decoration: none;
    color: $grey-dark;
}

.field a {
    color: $blue;
}

.node--full,
.node--teaser {
    .field--name-field-cat-breed,
    .field--name-field-dog-breed,
    .field--name-field-cat-colour,
    .field--name-field-dog-colour,
    .views-field-field-cat-breed,
    .views-field-field-dog-breed,
    .views-field-field-cat-colour,
    .views-field-field-dog-colour,
    .field--name-field-mum-dog-breed,
    .field--name-field-mum-cat-breed,
    .field--name-field-dad-dog-breed,
    .field--name-field-dad-cat-breed {
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: $font-weight--bold;
        letter-spacing: 2px;
        a {
            text-decoration: none;
        }
    }
}


.node-type-pet,
.node--pet,
.node--litter {
    .field--name-breeder-location {
        font-weight: $font-weight--light;
        letter-spacing: 1px;
    }
}


.node--pet--teaser {
    border: 1px solid $grey-light;
    padding-bottom: 20px;
    @include transition;
    &:hover {
        @include shadow;
        background: $blue;
        color: white;
        .field--name-title a,
        .field--type-taxonomy-term-reference a {
            color: white !important;
            &:hover {
                color: white !important;
            }
        }
        .field--type-image {
            a:after {
                opacity: 0.4;
            }
        }
    }
    .field {
        margin: 0 15px;
    }
    .field--type-image {
        margin: 0;
        position: relative;
        a {
            display: block;
            &:after {
                content: '';
                height: 100%;
                width: 100%;
                background: $blue;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                @include transition;
            }
        }
    }
    .field--name-title {
        border-bottom: 1px solid $grey-light;
        margin-bottom: 15px;
        h4 {
            margin-bottom: 10px;
        }
    }
    .field--type-taxonomy-term-reference {
        margin-bottom: 5px;
        a {
            color: $blue;
        }
    }
}

.node-type-pet {
    .l-region--content {
        padding-top: 0px;
    }
}

.node--pet--full {
    @include breakpoint($desktop) {
        display: flex;
        flex-wrap: wrap;
    }
    .field--name-title {
        margin-bottom: 0px;
        h2 {
            border-bottom: 1px solid $grey-mid;
            padding-bottom: $spacing;
            margin-bottom: 0;
        }
    }
    .group-header {
        border-bottom: 1px solid $grey-mid;
        flex: 0 1 100%;
    }
    > .group-left {
        float: none;
        width: 100%;
        @include breakpoint($desktop) {
            flex: 0 1 50%;
            padding-right: 30px;
        }
    }
    > .group-right {
        float: none;
        width: 100%;
        @include breakpoint($desktop) {
            flex: 0 1 50%;
            padding-left: 30px;
            -ms-flex-preferred-size: 40%;
        }
    }
    .group-footer {
        flex: 0 1 100%;
    }
    .field {
        margin: 20px 0;
    }
    .field--name-author {
        .field__label {
            margin-right: 5px;
        }
    }
    .field--name-field-cat-breed,
    .field--name-field-dog-breed,
    .field--name-field-cat-colour,
    .field--name-field-dog-colour {
        display: inline-block;
        margin-right: 12px;
        .field__item {
            @include h3;
        }
    }
    .field--name-breeder-location,
    .field--name-breeder-location p {
        margin-top: 0px;
    }
    .field--name-field-image {
        @include photo-gallery;
    }
    .flag-outer-watchlist {
        @include breakpoint($tablet) {
            float: right;
            margin-top: 20px;
        }
        a.flag-action,
        a.unflag-action {
            @include button--small;
        }
    }
}

.node--pet--details {
    border-top: 1px solid $grey-mid;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .field {
        margin-bottom: 20px;
        margin-right: 20px;
    }
    .field.field--name-field-image {
        flex-grow: 0;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .field.field--name-title {
        flex-grow: 1;
        flex-basis: calc(100% - 120px);
        margin-right: 0px;
    }
}


// Pet slideshow colorbox

#colorbox {
    overflow: visible;
    #cboxWrapper {
        background: $grey-dark;
        border-radius: 0px;
        overflow: visible;
    }
    #cboxContent {
        background: $grey-dark;
        overflow: visible;
    }
    #cboxTitle {
        background: rgba(0,0,0,0.7);
        color: white;
    }
    #cboxCurrent {
        color: white;
        bottom: 0px;
    }
    #cboxNext {
        right: 20px;
        left: auto;
        top: 45%;
        bottom: auto;
        background: none;
        width: 35px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/next.png');
    }
    #cboxPrevious{
        left: 20px;
        right: auto;
        top: 45%;
        bottom: auto;
        background: none;
        width: 35px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/previous.png');
    }
    #cboxClose {
        top: -50px;
        top: 20px;
        right: -50px;
        right: 20px;
        width: 30px;
        height: 30px;
        background: none;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../images/close.png');
    }
}

// ------------ FLAG ------------
a.flag-action,
a.unflag-action {
    @include button--small;
}


// ------------ Litters ------------
.node--litter--teaser {
    border: 1px solid $grey-light;
    padding-bottom: 20px;
    @include transition;
    &:hover {
        @include shadow;
        background: $blue;
        color: white;
        .field--name-title a,
        .field--type-taxonomy-term-reference,
        .field--type-taxonomy-term-reference a {
            color: white !important;
            &:hover {
                color: white !important;
            }
        }
        .field--type-image {
            a:after {
                opacity: 0.4;
            }
        }
    }
    .field {
        margin: 0 15px;
    }
    .field--type-image {
        margin: 0;
        position: relative;
        a {
            display: block;
            &:after {
                content: '';
                height: 100%;
                width: 100%;
                background: $blue;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                @include transition;
            }
        }
    }
    .field--name-title {
        border-bottom: 1px solid $grey-light;
        margin-bottom: 15px;
        h4 {
            margin-bottom: 10px;
        }
    }
    .field--type-taxonomy-term-reference {
        margin-bottom: 5px;
        color: $blue;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .group-photos {
        display: flex;
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 50%;
            background: $grey-mid;
        }
    }
    .field--name-field-mum-dog-breed,
    .field--name-field-mum-cat-breed {
        .field__item:after {
            content: '/';
            display: inline-block;
            margin: 0 5px;
        }
    }
    .field--type-datetime {
        .field__label {
            font-weight: 300;
        }
    }
}

.node--litter--full {
    @include breakpoint($desktop) {
        display: flex;
        flex-wrap: wrap;
    }
    .field--name-title {
        margin-bottom: 0px;
        h2 {
            border-bottom: 1px solid $grey-mid;
            padding-bottom: $spacing;
            margin-bottom: 0;
        }
    }
    .group-header {
        border-bottom: 1px solid $grey-mid;
        flex: 0 1 100%;
    }
    > .group-left {
        float: none;
        width: 100%;
        @include breakpoint($desktop) {
            flex: 0 1 50%;
            padding-right: 30px;
        }
    }
    > .group-right {
        float: none;
        width: 100%;
        @include breakpoint($desktop) {
            flex: 0 1 50%;
            padding-left: 30px;
            -ms-flex-preferred-size: 40%;
        }
    }
    .group-footer {
        flex: 0 1 100%;
    }
    .field {
        margin: 20px 0;
    }
    .field--name-author {
        .field__label {
            margin-right: 5px;
        }
    }
    .field--name-breeder-location,
    .field--name-breeder-location p {
        margin-top: 0px;
    }
    .field--name-field-mum-photos,
    .field--name-field-dad-photos {
        @include photo-gallery;
    }
    .flag-outer-watchlist {
        @include breakpoint($tablet) {
            float: right;
            margin-top: 20px;
        }
        a.flag-action,
        a.unflag-action {
            @include button--small;
        }
    }
}
