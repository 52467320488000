
// -------------- User --------------
.user-profile {
    .field,
    .user-profile-item {
        margin-bottom: 20px;
    }
    .field--label-above {
        .field__label {
            margin-bottom: 10px;
        }
    }
    .field--type-text-long {
        p:first-child {
            margin-top: 0px;
        }
    }
    //  Full display --------------
    &.view-mode-full {
        &.ds-2col-stacked {
            @include breakpoint($desktop) {
                display: flex;
                flex-wrap: wrap;
            }
            .group-header {
                border-bottom: 1px solid $grey-mid;
                margin-bottom: 30px;
                flex: 0 1 100%;
            }
            > .group-left {
                float: none;
                width: 100%;
                @include breakpoint($desktop) {
                    flex: 0 1 50%;
                    padding-right: 30px;
                }
            }
            > .group-right {
                float: none;
                width: 100%;
                @include breakpoint($desktop) {
                    flex: 0 1 50%;
                    padding-left: 30px;
                    -ms-flex-preferred-size: 45%;
                }
            }
            .group-footer {
                flex: 0 1 100%;
            }
        }
        .group-left {
            .field--name-name.field {
                border-bottom: 0px;
            }
            .field--label-inline {
                display: flex;
                border-bottom: 1px solid $grey-light;
                padding-bottom: 10px;
                .field__label {
                    margin-right: 20px;
                    flex: 0 1 220px;
                }
                .field__items {
                    flex: 1;
                }
            }
            .field--label-above {
                border-bottom: 1px solid $grey-light;
                padding-bottom: 10px;
            }
        }
        .field--name-field-breeding-name,
        .field.field--name-field-display-name {
            @include h1;
            margin: 20px 0;
        }
        .field.field--name-field-display-name  {
            float: left;
            display: block;
            margin-top: 0px;
        }
        .field.field--name-user-location {
            clear: both;
            float: left;
            p {
                margin: 0;
                letter-spacing: 1px;
            }
        }
        .field--name-field-image {
            @include photo-gallery
        }
        .flag-outer {
            @include breakpoint($tablet) {
                float: right;
                margin-bottom: 20px;
            }
            a.flag-action,
            a.unflag-action {
                @include button--small;
            }
        }
    }
}

// -------------- User : Teaser --------------
.user-profile.view-mode-teaser {
    border: 1px solid $grey-light;
    padding-bottom: 20px;
    @include transition;
    &:hover {
        @include shadow;
        background: $blue;
        color: white;
        .field--name-name a {
            color: white !important;
            &:hover {
                color: white !important;
            }
        }
        .field--type-image {
            a:after {
                opacity: 0.4;
            }
        }
    }
    .field {
        margin: 0 15px;
            &.field--name-field-breeding-name {
                border-bottom: 2px solid $blue;
                margin: 15px;
                padding-bottom: 15px;
                @include h3;
                line-height: 26px;
            }
            &.field--name-field-display-name {
                margin-bottom: 10px;
            }
            &.field--name-field-cat-breed,
            &.field--name-field-dog-breed {
                margin-bottom: 5px;
                .field__items {
                    .field__item {
                        display: inline-block;
                    }
                    .field__item:after {
                        content: ', ';
                        margin-right: 4px;
                    }
                    .field__item:last-child:after {
                        content: '';
                    }
                }
            }
    }
    .field--type-image {
        margin: 0;
        position: relative;
        a {
            display: block;
            &:after {
                content: '';
                height: 100%;
                width: 100%;
                background: $blue;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                @include transition;
            }
        }
    }
    // No empty paws on teasers
    .field--name-field-rating .field__items .fivestar-outline .fivestar-widget-static .star .off,
    .field--name-field-rating .field__items .fivestar-widget-static .star .off {
        background-image: none;
        display: none;
    }
    .fivestar-widget-static.empty {
        display: none;
    }
}

// -------------- User account information page --------------
.user-profile.view-mode-account_information {
    .field--label-inline {
        display: flex;
        border-bottom: 1px solid $grey-light;
        padding-bottom: 10px;
        .field__label {
            margin-right: 20px;
            flex: 0 1 220px;
        }
        .field__items {
            flex: 1;
        }
        p {
            margin: 0px;
        }
    }
}
.view-account-information .button {
    @include button--small;

}

// -------------- Ratings --------------
.field--name-field-rating {
    .form-item {
        margin: 0px;
    }
    .field__items {
        margin: 10px 0;
        .fivestar-outline .fivestar-widget-static .star,
        .fivestar-widget-static .star  {
            height: 25px;
            width: 25px;
            background-image: none;
            padding: 1px;
            margin: 0 3px 0 0;
            span {
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
            .on {
                background-image: url('../images/paw-filled.png');
                background-position: center;
            }
            .off {
                background-image: url('../images/paw-empty.png');
                background-position: center;
            }
        }
    }
}
