// Main menu
.l-region--navigation {
    background: $grey-dark;
    align-self: stretch;
    display: none;
    @include breakpoint($desktop) {
        display: block;
    }
    ul.menu {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        @include plain-list;
        li {
            flex: 1;
            flex: 1 0 auto;
            text-align: center;
            &:first-child {
                a {
                    padding-left: 18px;
                }
            }
            &:last-child {
                a {
                    padding-right: 18px;
                }
            }
            a {
                padding: 18px 12px 16px 12px;
                color: white;
                display: block;
                text-transform: uppercase;
                transition: 0.3s ease background-color;
                letter-spacing: 2px;
                font-size: $font-size--small;
                text-decoration: none;
                &:hover {
                    background-color: $blue;
                    text-decoration: none;
                }
                &.active {
                    background: $grey-mid;
                }
            }
        }
    }
}

// Mobile

.l-region--mobilenavigation {
    display: block;
    @include breakpoint($desktop) {
        display: none;
    }
    &.closed {
        .block--menu-block {
            height: 0px;
            .block__content {
                position: relative;
                top: -200px;
            }
        }
    }
    &.open {
        .block--menu-block {
            height: auto;
            .block__content {
                position: relative;
                top: 0px;
            }
        }
    }
    .block--menu-block {
        .block__content {
            transition: 0.4s ease all;
        }
    }
    a.button {
        text-align: right;
        display: block;
        margin: 20px 0;
        background: transparent;
        @include breakpoint(0 $mobile--tiny) {
            margin-top: 0px;
            margin-bottom: 0px;
            padding-right: 10px;
        }
    }
    // block styling
    .block--menu-block {
        @include plain-list;
        overflow: hidden;
        position: absolute;
        background: $grey-dark;
        width: 100%;
        z-index: 9;
        left: 0;
        ul.menu {
            margin: 0px;
            padding: 0px;
            li {
                a {
                    display: block;
                    padding: 15px;
                    color: white;
                    border-bottom: 1px solid $grey-mid;
                    &:hover {
                        background: $blue;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
