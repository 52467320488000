
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600');

$font-weight--light: 100;
$font-weight--regular: 300;
$font-weight--bold: 600;

$font-size--small: 12px;
$font-size--regular: 14px;

$font--line-height: 20px;

$font-color--regular: $grey-font;

$font-family: 'Montserrat', sans-serif;

body {
    font-family: $font-family;
    font-size: $font-size--regular;
    font-weight: $font-weight--regular;
    line-height: $font--line-height;
    color: $font-color--regular;
}

@mixin a {
    text-decoration: underline;
    color: $grey-font;
    transition: 0.3s ease color;
    &:hover {
        color: $blue;
        text-decoration: underline;
    }
}

a {
    @include a;
}

@mixin p {
    font-size: $font-size--regular;
    line-height: $font--line-height;
}

p {
    @include p;
}

@mixin small-text {
    font-size: $font-size--small;
    font-weight: 400;
    letter-spacing: 1px;
}

@mixin h1 {
    font-size: 38px;
    line-height: 38px;
    color: $grey-dark;
    font-weight: $font-weight--bold;
}
h1 {
    @include h1;
}

@mixin h2 {
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 1px;
    font-weight: $font-weight--bold;
}
h2 {
    @include h2;
}

@mixin h3 {
    font-size: 24px;
    line-height: 24px;
    font-weight: $font-weight--bold;
}
h3 {
    @include h3;
}

@mixin h4 {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: $font-weight--bold;
}
h4 {
    @include h4;
}

.field--name-body,
.field--type-text-long,
.field--type-text-with-summary {
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
}

blockquote {
    border-left: 1px solid $grey-light;
    background: $grey-superlightlight;
    padding: $spacing;
    margin: $spacing 0;
}
@mixin blockquote {
    border-left: 1px solid $grey-light;
    background: $grey-superlightlight;
    padding: $spacing;
    margin: $spacing 0;
}
