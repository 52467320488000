#block-user-login {
    // max-width: 300px;
    form > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}

#block-views-pets-available-block {
    .view-content {
        flex: 1 1 auto;
    }
}

.page-user-edit {
    #block-views-pets-available-block-1 {
        display: none;
    }
}
