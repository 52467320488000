table {
    thead {
        tr {
            border-left: none;
            border-right: none;
        }
    }
    th {
        background-color: transparent;
        border-bottom: 1px solid $grey-light;
    }
    tr {
        border-left: 1px solid $grey-light;
        border-right: 1px solid $grey-light;
        background: transparent;
        border-bottom: 1px solid $grey-light;
    }
    tr.even,
    tr.odd {
        background: transparent;
    }
    td {
        border: inherit;
        padding: 10px;
        .form-item {
            margin: 0px;
        }
    }
    td.field-multiple-drag {
        display: none;
    }
}
