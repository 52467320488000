body {
    margin: 0px;
}

img, svg {
    max-width: 100%;
    height: auto;
}

a object {
    pointer-events: none;
}

html body.admin-menu {
    @include breakpoint(0 $mobile--tiny) {
        margin-top: 74px !important;
    }
}

html {
    // overflow-x: hidden;
}

.l-header {
    background: $grey-light;
    padding: 20px;
    @include breakpoint($desktop) {
        padding: 20px 20px 0px 20px;
    }
}

.l-header-container {
    @include container;
}

.l-content--page-info {
    padding: 0px;
    overflow: auto;
    @include container;
    @include breakpoint($desktop) {
        padding: $spacing;
    }
    @include breakpoint($tablet) {
        max-width: calc(100% - 300px);
        width: 100%;
        float: right;
        .tabs {
            margin-top: 0px;
        }
        .messages {
            clear: both;
        }
    }
}

.l-page {
    @include breakpoint(0 $mobile--tiny) {
        overflow-x: hidden;
    }
}

.l-region--contenttop {
    @include container;
}

.l-content.no-sidebar {
    h1.page-title,
    .tabs {
        max-width: 100%;
        float: none;
    }
    @include breakpoint($tablet) {
        overflow: auto;
        .l-content--flex-container {
            width: calc(100% - 300px);
            margin: 0 0 0 auto;
        }

    }
}

.l-main {
    @include breakpoint($desktop) {
        padding-top: 20px;
    }
}

.l-content {
    @include container;
    min-height: 300px;
    padding: $spacing;
    @include breakpoint($page-container) {
        padding: 0;
    }
}

.l-content--flex-container {
    clear: both;
    @include breakpoint($tablet) {
        padding: $spacing;
        display: flex;
    }
}

.l-content .l-region--sidebar {
    margin-bottom: 40px;
    @include breakpoint($tablet) {
        flex-basis: 300px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    @include breakpoint($page-container--outer) {
        padding: 0 $spacing $spacing 0;
    }
}

.l-content .l-region--content {
    padding-bottom: 60px;
    @include breakpoint($tablet) {
        flex-basis: 100%;
    }
}

.l-content .l-region--contentbottom {
    padding: 40px 0;
    border-top: 1px solid $grey-mid;
    @include breakpoint($page-container) {
        padding: 40px 20px;
    }
}

.l-footer {
    background: $grey-dark;
}
.l-region--footer {
    @include container;
    padding: $spacing;
}

img {
    max-width: 100%;
    height: auto;
}

ul.tabs {
    display: flex;
    li {
        flex-basis: 33%;
        align-items: stretch;
        display: flex;
        align-items: stretch;
        float: none;
        margin: 0;
        @include breakpoint($tablet) {
            flex-basis: auto;
            margin-right: 10px;
        }
        a {
            flex-basis: 100%;
            display: flex;
            align-items: center;
            &.active {
                text-decoration: underline;
            }
        }
    }
}
