// Also known as Expressions of Interest!

#comments,
.comment-form {
    .comments__title {
        border-bottom: 1px solid $blue;
        padding-bottom: $spacing;
    }
    .field--name-comment-body {
        margin: 30px 0px;
    }
    .indented {
        margin-left: ((2 * $spacing));
    }
    .comment {
        padding: $spacing 0;
        border-bottom: 1px solid $grey-mid;
        // comment title
        h3 {
            margin-top: 0px;
        }
        .submitted {
            // font-size: $font-size--small;
            @include h4;
        }
        .comment-links {
            .links, .permalink {
                display: inline-block;
                vertical-align: top;
            }
            .links a, .permalink {
                @include button--small;
                display: inline-block;
            }
            .permalink {
                display: none;
            }
        }

    }
    .comments__form-title {
        margin-top: ((3 * $spacing));
    }
    .filter-wrapper {
        font-size: $font-size--small;
        display: none;
    }
    .form-item-notify {
        margin: $spacing 0;
        display: none;
    }
}

.page-comment-reply {
    .field--name-comment-body {
        margin: 20px 0;
    }
    .comment-links {
        display: none;
    }
}

// Sent & Received Messages pages

.block .view-messages-of-interest {
    margin-top: ((2 * $spacing));
    .views-row {
        border: 1px solid $grey-light;
        padding: 20px;
        @include transition;
        &:hover {
            @include shadow;
        }
        a {
            color: $blue;
        }
        .views-field-view-comment a {
            @include button--small;
            display: inline-block;
        }
        .views-field-title {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid $grey-light;
            margin-bottom: 10px;
            h3 {
                margin: 0px; a {
                    color: $grey-mid;
                }
            }
        }
        // Who made the comment
        .views-field-name {
            margin-top: 10px;
            margin-bottom: 10px;
            padding-top: 10px;
            border-top: 1px solid $grey-light;
        }
    }
}
