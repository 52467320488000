form {
    input[type='submit'] {
        @include button;
    }
    input[type='text'],
    input[type='password'],
    input[type='file'],
    input[type='email'],
    textarea {
        font-size: $font-size--regular;
        padding: 7px 5px;
        max-width: 100%;
        @include breakpoint($mobile) {
            border: 1px solid $grey-mid;
        }
    }
    .form-item {
        margin-bottom: 20px;
        label {
            font-weight: $font-weight--regular;
            color: $grey-dark;
        }
        .description {
            margin: 10px 0;
        }
        &.form-type-select {
            label {
                margin: 10px 0;
            }
            select {
                font-size: 14px;
                option {
                    font-size: 14px;
                }
            }
        }
        &.form-type-checkboxes,
        &.form-type-radios {
            label {
                margin: 10px 0;
            }
        }
    }
    .form-actions {
        margin: ((2 * $spacing)) 0;
        input {
            display: inline-block;
            margin-right: $spacing;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .form-type-managed-file {
        .image-widget-data {

            @include breakpoint($tablet) {
                float: none;
                display: flex;
                justify-content: space-between;
                border: 1px solid $grey-mid;
                align-items: center;
                padding-left: 20px;
                margin: 10px 0;
                width: 100%;
            }
        }
    }
    .image-widget.form-managed-file {

        @include breakpoint($tablet) {
            display: flex;
        }
    }
    .field-widget-image-image {
        .tabledrag-toggle-weight-wrapper {
            display: none;
        }
        table.tabledrag-processed {
            width: 100%;
        }
        .tabledrag-handle .handle {
            box-sizing: content-box;
        }
        .warning.tabledrag-changed,
        .tabledrag-changed-warning {
            display: none !important;
        }
    }
}

// --------------- User Registration form ---------------
form#user-register-form,
form#user-profile-form {
    .form-wrapper {
        label {
            font-weight: 600;
            &.option {
                font-weight: 300;
            }
        }
    }
    .tabledrag-toggle-weight-wrapper {
        display: none;
    }
    .group-buyers.field-group-fieldset {
         > .fieldset-wrapper {
             padding: 0px;
        }
    }
    table {
        width: 100%;
        max-width: 100%;
        .tabledrag-handle {
            display: none;
        }
        th {
            padding: 10px 0 0 0;
            &:last-child {
                display: flex;
                justify-content: flex-end;
            }
        }
        tr td {
            padding: 10px 0;
        }
        tr td:last-child {
            display: flex;
            justify-content: flex-end;
        }
    }
    table, thead, tbody, th, tr, td {
        display: block;
        max-width: 100%;
    }
    .field-multiple-drag {
        display: none;
    }
    #field-phone-number-values {
        width: auto;
        td {
            padding: 10px;
        }
    }
    .field-name-field-at-home,
    .field-name-field-exercise {
        padding: 0 $spacing;
    }
    .field-name-field-bio {
        #edit-field-bio-und-0-format {
            display: none;
        }
        .description {
            background: $grey-light;
            padding: 20px;
            margin: 0px;
        }
    }
    .field-name-field-buyer-seller {
        > .form-item > label {
            @include h2;
            margin-bottom: 10px;
        }
    }
    #edit-comment-notify-settings,
    #edit-mimemail,
    #edit-timezone {
        background: transparent;
        .summary {
            background: transparent;
            padding: 0;
        }
        .fieldset-wrapper {
            padding: 0;
        }
    }
    #edit-terms-of-use {
        position: relative;
        legend,
        .fieldset-wrapper {
            background-color: $grey-light;
        }
        .fieldset-wrapper {
            max-height: 500px;
            overflow-y: scroll;
            > p:first-child {
                margin-top: 0;
            }
            ul {
                list-style-type: disc;
            }
            ol {
                list-style-type: decimal;
            }
        }
        .form-item-terms-of-use {
            position: sticky;
            bottom: -20px;
            padding: 20px;
            margin-left: -20px;
            margin-right: -20px;
            background: $grey-light;
            @include box-shadow;
        }
    }
}

.form-wrapper {
    margin: ((2 * $spacing)) 0;
}

fieldset {
    .field-type-text-long {
        margin: $spacing;
    }
    fieldset.form-wrapper {
        margin-bottom: 0px;
        .field-type-text-long {
            margin: 0;
        }
    }
    .fieldset-wrapper {
        .field-type-text-long {
            margin: 0px;
        }
        .form-wrapper {
            margin-top: 0;
        }
    }
}

fieldset.form-wrapper {
    border: none;
    padding: 0px;
    margin: 0px;
    background: $grey-light;
    display: block;
    margin: $spacing 0 (2 * $spacing) 0;
    legend {
        padding: 0px;
        margin: 0px;
        width:100%;
        span.fieldset-legend {
            display: block;
            width: 100%;
            background: $grey-light;
            padding: $spacing;
            @include h4;
        }
    }
    .fieldset-wrapper {
        padding: $spacing;
        margin: 0px;
        display: block;
    }
    &.collapsible {
        legend {
            span.fieldset-legend {
                background: $grey-light;
            }
        }
    }
    &.collapsed {
        margin-bottom: (3 * $spacing);
    }
    // Nested fieldsets
    fieldset.form-wrapper {
        legend {
            span.fieldset-legend {
                padding: $spacing 0;
            }
        }
        .fieldset-wrapper {
            padding: $spacing 0;
        }
    }
    // Regular form elements nested need margins
    .fieldset-wrapper > div.form-wrapper {
        margin-bottom: $spacing;
    }
    &.date-combo {
        background: none;
        legend span.fieldset-legend {
            background: none;
            padding: 0px;
        }
        .fieldset-wrapper {
            padding: 0px;
        }
        .date-padding {
            padding: $spacing 0 0 0;
        }
    }
}

.field-type-text-long,
.field-type-text-with-summary {
    .filter-wrapper {
        margin-top: 0px;
        display: none;
        .fieldset-wrapper {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            ul.tips {
                padding: 0px;
            }
        }
        .form-wrapper {
            margin: 0px;

        }
    }
    .form-type-select {
        display: none;
    }
    .filter-guidelines {
        padding: 0;
    }
    .filter-help p {
        margin: 0;
    }
}

html.js input.form-autocomplete {
    background-position: 100% 11px;
}

html.js input.throbbing {
    background-position: 100% -9px;
}

#pet-node-form {
    #edit-field-cat-breed-und,
    #edit-field-dog-breed-und {
        height: 200px;
        option {
            font-size: 14px;
            padding: 3px;
        }
    }
}

.g-recaptcha {
    margin: 40px 0;
}
