.view {
    .more-link {
        a {
            @include button;
        }
    }
}
.block--views .views-exposed-form {
    .views-exposed-widgets {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: $grey-superlightlight;
        .views-exposed-widget {
            flex: 0 0 100%;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid $grey-lightlight;
            transform: scaleX(1);
            transform-origin: top;
            label {
                font-weight: $font-weight--regular;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: $grey-dark;
                margin-bottom: 10px;
                display: block;
            }
            &.hide {
                transform: scaleY(0);
                padding: 0px;
                border: 0px;
                margin: 0px;
                label {
                    display: none;
                }
            }
            &.show {
                transform: scaleY(1);
                label {
                    display: block;
                }
            }
            select.form-select {
                width: 100%;
                font-size: 14px;
                background: $grey-light;
                color: $grey-dark;
                padding: 0px 5px;
                max-height: 220px;
                option {
                    padding: 7px 5px;
                    white-space: normal;
                    border-bottom: 1px solid $grey-superlightlight;
                }
            }
        }
        .views-submit-button,
        .views-reset-button {
            border: 0px;
            flex: 0 0 45%;
            padding-right: 0px;
            margin: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            margin-bottom: 10px;
            input[type="submit"] {
                width: 100%;
                position: relative;
                box-sizing: border-box;
                padding-bottom: 15px;
            }
        }
    }

}

.ajax-progress-throbber {
    background-color: rgba(248, 248, 248,0.6);
    background-image: url('../images/loading.gif');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 16px;
    left: 0;
    height: 45px;
    width: 100%;
}
.ajax-progress .throbber {
  display: none;
}

// --------------------- Frontpage pets available view ---------------------
.view-pets-available {
    .pager {
        display: flex;
        justify-content: center;
        flex: 1 1 100%;
    }
}
// Views exposed form block
#block-views-exp-pets-available-page {
    .views-widget-filter-field_price_value {
        > label {
            &:after {
                content: 'between ';
            }
        }
        .form-item-field-price-value-max {
            margin-top: 5px;
        }
    }
}
// Location
#edit-field-location-taxonomize-terms-tid-wrapper,
#edit-taxonomy-entity-index-tid-depth-wrapper {
    option.top {
        background-color: #f9f9f9;
        // font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-left: -5px;
        margin-right: -5px;
        padding-left: 10px;
    }
}

// --------------------- Breeder directory ---------------------
.view-breeder-directory {
    .view-filters {
        .views-exposed-widgets {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background: $grey-superlightlight;
            padding: $spacing;
            .views-exposed-widget {
                flex: 0 0 100%;
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid $grey-lightlight;
                transform: scaleX(1);
                transform-origin: top;
                label {
                    font-weight: $font-weight--regular;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: $grey-mid;
                    margin-bottom: 10px;
                    display: block;
                }
                &.hide {
                    transform: scaleY(0);
                    padding: 0px;
                    border: 0px;
                    margin: 0px;
                    label {
                        display: none;
                    }
                }
                &.show {
                    transform: scaleY(1);
                    label {
                        display: block;
                    }
                }
                select.form-select {
                    width: 100%;
                    font-size: 14px;
                    background: $grey-light;
                    color: $grey-dark;
                    padding: 0px 5px;
                    max-height: 220px;
                    option {
                        padding: 7px 5px;
                        white-space: normal;
                        border-bottom: 1px solid $grey-superlightlight;
                    }
                }
            }
            .views-submit-button,
            .views-reset-button {
                border: 0px;
                flex: 0 0 45%;
                padding-right: 0px;
                margin: 0px;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-bottom: 10px;
                input[type="submit"] {
                    width: 100%;
                    position: relative;
                    box-sizing: border-box;
                    padding-bottom: 15px;
                }
            }
        }

    }
    .pager {
        display: flex;
        justify-content: center;
        flex: 1 1 100%;
    }
}

.view-approved-buyers {
    .views-row {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $grey-light;
        @include breakpoint($tablet) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
        .views-field {
            flex-basis: 100px;
            margin: 0 10px;
            &:last-child {
                margin-right: 0px;
            }
            &:first-child {
                margin-left: 0px;
            }
            &:not(.views-field-ops) a {
                color: $blue;
            }
        }
        .views-field-timestamp,
        .views-field-field-image {
            flex-basis: 70px;
            flex-grow: 0;
        }
        .views-field-field-notes {
            flex-basis: 150px;
        }
        .views-field-ops {
            flex-basis: 70px;
            text-align: right;
        }
    }
}

// Accordian block on the user page
#block-views-pets-available-block-1,
#block-views-litters-block-2 {
    .ui-accordion-header {
        font-family: $font-family;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        color: $font-color--regular;
        background: $grey-superlightlight;
        &.ui-state-active {
            background: $grey-light;
            border-color: $grey-dark;
        }
    }
    .ui-accordion-content {
        background: $grey-light;
        border-color: $grey-dark;
        max-height: 750px;
    }
    .views-row {
        margin-bottom: $spacing;
        background: white;
        display: inline-block;
        border: 1px solid #bbbbbb;
        padding-bottom: 20px;
        max-width: 400px;
        @include transition;
        @include breakpoint($tablet) {
            width: calc(50% - 20px);
            vertical-align: top;
            margin-right: $spacing;
        }
        @include breakpoint($desktop) {
            width: calc(33% - 20px);
            &:nth-child(3n + 3) {
                margin-right: 0px;
            }
        }
        &:hover {
            @include shadow;
            background: $blue;
            color: white;
            .views-field-title a,
            .views-field-field-cat-breed,
            .views-field-field-dog-breed,
            .views-field-field-region {
                color: white !important;
                &:hover {
                    color: white !important;
                }
                .field-content {
                    color: white;
                }
            }
            .views-field-field-image {
                a:after {
                    opacity: 0.4;
                }
            }
        }
        .views-field {
            margin: 0 15px;
            font-family: $font-family;
            font-size: $font-size--regular;
            font-weight: $font-weight--regular;
            line-height: $font--line-height;
            color: $font-color--regular;
        }
        .views-field-field-image {
            margin: 0;
            position: relative;
            a {
                display: block;
                &:after {
                    content: '';
                    height: 100%;
                    width: 100%;
                    background: $blue;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include transition;
                }
            }
        }
        .views-field-title {
            border-bottom: 1px solid $grey-light;
            margin-bottom: 15px;
            h4 {
                margin-bottom: 10px;
                a {
                    text-decoration: none;
                    color: $grey-dark;
                }
            }
        }
        .views-field-field-cat-breed,
        .views-field-field-dog-breed,
        .views-field-field-mum-dog-breed,
        .views-field-field-dad-dog-breed,
        .views-field-field-mum-cat-breed,
        .views-field-field-dad-cat-breed {
            margin-bottom: 5px;
            .field-content {
                color: $blue;
                letter-spacing: 2px;
                line-height: 20px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
        // Litter only fields
        .views-field-field-mum-cat-breed,
        .views-field-field-mum-dog-breed {
            .field-content:after {
                content: '/';
                display: inline-block;
                margin: 0 5px;
            }
        }
        .views-field-field-mum-photos,
        .views-field-field-dad-photos {
         a {
             display: block;
             img {
                 display: block;
             }
         }
        }
        .group-photos {
            display: flex;
            position: relative;
            margin: 0 -15px;
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 1px;
                height: 100%;
                left: 50%;
                background: $grey-mid;
            }
        }
    }
}

// ------------------- Litters page -------------------
#block-views-exp-litters-page {
    @include breakpoint($tablet) {
        margin-top: -41px;
    }
}
