.l-region--footer {
    color: white;
    @include breakpoint($desktop) {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
    }
    a {
        color: white;
        text-decoration: underline;
        &:hover {
            color: white;
        }
    }
    .block--menu-block-1 {
        flex: 0 1 auto;
        margin-top: 40px;
        @include breakpoint($desktop) {
            margin-top: 0px;
        }
    }
    ul.menu {
        @include plain-list;
        li {
            padding: 10px 0;
            @include breakpoint($desktop) {
                display: inline-block;
                padding: 0;
            }
            a {
                margin-right: 30px;
                text-decoration: none;
                text-transform: uppercase;
                margin-bottom: 10px;
                letter-spacing: 2px;
                font-weight: $font-weight--light;
                color: white;
                font-size: 12px;
                @include breakpoint($desktop) {
                    margin-left: 60px;
                    margin-right: 0px;
                }
            }
        }
    }

}

.block.built-by {
    margin-top: $spacing;
    border-top: 1px solid $grey-mid;
    font-size: 12px;
    flex: 1 1 100%;
    p {
        margin-bottom: 0px;
        text-transform: uppercase;
        margin-bottom: 10px;
        letter-spacing: 2px;
        font-weight: $font-weight--light;
        font-size: 12px;
        a {
            text-decoration: none;
            color: white;
        }
    }
}
