.l-header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include breakpoint(0 $mobile--tiny) {
        position: relative;
    }
    .l-branding {
        flex: 1 0 100%;
        flex: 0 1 auto;
        @include breakpoint(0 $mobile--tiny) {
            flex: 1;
            flex-basis: 100%;
            text-align: center;
            margin-bottom: 10px;
            flex-grow: 0;
            flex-basis: 50%;
        }
        a.site-logo {
            display: block;
            img {
                @include breakpoint(0 $mobile--tiny) {
                    max-width: 120px;
                }
                max-width: 200px;
            }
        }
        @include breakpoint($desktop) {
            flex: 0 1 auto;
            a.site-logo {
                display: block;
                position: relative;
                margin-bottom: -80px;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    .header-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include breakpoint(0 $mobile--tiny) {
            width: 50%;
        }
        @include breakpoint($mobile--tiny) {
            flex: 1;
        }
        @include breakpoint($desktop) {
            justify-content: space-between;
            align-items: flex-end;
            flex: 0 1 calc(100% - 300px);
            -ms-flex: 0 1 760px;
            flex-basis: calc(100% - 300px);
        }
    }
    .l-region--header {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-end;
        @include breakpoint($desktop) {
            align-items: center;
        }
    }
}

// User menu
.block--system-user-menu {
    ul.menu {
        @include plain-list;
        display: flex;
        li {
            a {
                display: inline-block;
                padding: 5px;
                font-size: $font-size--small;
                font-weight: $font-weight--regular;
                text-transform: uppercase;
                letter-spacing: 2px;
                text-decoration: none;
            }
            &:first-child {
                &:after {
                    content: '/';
                    margin: 0 10px;
                }
            }
        }
        &:after {
            content: '';
            width: 23px;
            height: 23px;
            background-image: url('../images/account.svg');
            background-size: contain;
            margin-left: 10px;
            background-repeat: no-repeat;
            position: relative;
            top: 3px;
        }
    }
}

.block--user-login {
    @include breakpoint($desktop) {
        margin: 0;
    }
    .block__content {
        > div {
            display: inline-block;
        }
        @include breakpoint(400px) {
            &:after {
                content:"/";
                display: inline;
                padding: 5px;
            }
        }
    }
    &.show {
        .block__content {
            display: block;
            @include breakpoint(0 400px) {
                &:after {
                    content:"/";
                    display: none;
                    padding: 5px;
                }
            }
            @include breakpoint(400px) {
                &:after {
                    display: none;
                }
            }
        }

    }
    .open-login {
        font-size: $font-size--small;
        font-weight: $font-weight--regular;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 5px;
        display: inline-block;
        text-decoration: none;
        &:hover {
            cursor: pointer;
        }
    }
    .login-content {
        transition: 0.3s ease all;
    }
    .hide {
        opacity: 0;
        position: absolute;
        top: -500px;

    }
    .show {
        opacity: 1;
    }
    form {
         > div {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
        input {
            border: 0px;
        }
        ul {
            margin: 0px $spacing 0px $spacing;
            padding: 0px;
            li {
                &:first-child {
                    margin-top: 15px;
                    margin-bottom: 3px;
                }
                a {
                    font-size: 12px;
                    font-weight: $font-weight--regular;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    display: block;
                    line-height: 16px;
                    text-decoration: none;
                }
            }
        }
        label {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
    .form-item {
        margin-left: $spacing;
        margin-bottom: 0px;
    }
    .form-actions {
        margin:0;
        input[type="submit"] {
            padding: 16px 15px;
        }
    }
}

.block.user-register {
    // margin-bottom: $spacing;
    @include breakpoint($desktop) {
        margin: 0;
    }
    &.hide {
        display: none;
    }
    .block__content {
        display: inline-block;
        position: relative;
        padding-right: 30px;
        @include breakpoint(0 $mobile--tiny){
            padding-right: 0px;
        }
        &:after {
            content: ' ';
            width: 23px;
            height: 23px;
            background-image: url('../images/account.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-left: 10px;
            position: absolute;
            top:3px;
            right: 0;
            @include breakpoint(0 $mobile--tiny){
                display: none;
            }
        }
    }
    p {
        margin: 0;
        a {
            padding: 5px;
            font-size: $font-size--small;
            font-weight: $font-weight--regular;
            text-transform: uppercase;
            letter-spacing: 2px;
            display: inline-block;
            text-decoration: none;
            // @include breakpoint()
        }
    }
}

.block--search-form {
    margin-left: $spacing;
    flex: 0 1 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing;
    &.login-open {
        margin-bottom: 0px;
    }
    @include breakpoint($desktop) {
        display: block;
        flex: 0 1 auto;
        margin-top: 0;
    }
    .hide {
        display: none;
    }
    .show {
        display: block;
    }
    .search-block-form {
        .container-inline {
            display: flex;
            input[type='text']{
                padding: 10px 5px;
                border: 0px;
                height: 50px;
                border-radius: 3px 0px 0px 3px
            }
            h2.element-invisible {
                margin: 0px;
            }
        }

        input[type='submit'] {
            background: white;
            height: 50px;
            text-indent: -999px;
            background-image: url('../images/search.svg');
            background-size: 35px;
            background-repeat: no-repeat;
            background-position: center;
            width: 60px;
            border-radius: 0 3px 3px 0
        }
    }
    .form-item {
        margin: 0px;
    }
}


.block.messages-link {
    margin-left: 15px;
    margin-top: 8px;
    @include breakpoint($desktop) {
        margin-top: 0px;
    }
    @include breakpoint($tablet) {
        &:before {
            content: '';
            position: absolute;
            height: 33px;
            border-left: 1px solid $grey-mid;
            top: -5px;
        }
    }

    .contextual-links-wrapper {
        position: absolute;
        right: -25px;
    }
    .block__content a {
        padding: 15px;
    }
    p {
        position: absolute;
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
}
