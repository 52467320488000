.l-region--sidebar {
    @include breakpoint($tablet) {
        padding-right: $spacing;
    }
    .block {
        margin-bottom: ((2 * $spacing));
        h2.block__title {
            text-transform: uppercase;
            @include small-text;
            padding-left: $spacing;
        }
        .block__content {
            background: $grey-superlightlight;
            padding: $spacing;
            &.menu-block-wrapper {
                padding: 0;
            }
        }
        ul.menu {
            list-style-image: none;
            padding: 0;
            margin: 0;
            li.leaf {
                list-style: none;
                list-style-image: none;
                a {
                    border-bottom: 1px solid $grey-light;
                    display: block;
                    padding: $spacing;
                    text-decoration: none;
                    &:hover {
                        text-decoration: none;
                        background: $grey-lightlight
                    }
                }
                &:last-child {
                    a {
                        border: 0px;
                    }
                }
            }
        }
    }
    > .block {
        h2.block__title {
            margin-top: 0;
        }
    }
}
