$container: 1280px;
$spacing: 20px;

$grey-superlightlight: #f8f8f8;
$grey-lightlight: #eeeeee;
$grey-light: #e8e7e7;
$grey-mid: #6c6c6c;
$grey-dark: #555555;

$grey-font: #3a3a3a;

$blue: #14b9e0;

$mobile-only--tiny: 0 485px;
$mobile--tiny: 485px;
$mobile: 0 680px;
$tablet: 680px;
$desktop: 1080px;
$page-container: 1280px;
$page-container--outer: 1345px;
$footer: 980px;
