.front {
    h1.page-title {
        // display: none;
    }
    .l-header {
        position: relative;
        z-index: 9;
    }
    #block-views-exp-pets-available-page {
        @include breakpoint($desktop) {
            margin-top: -42px;
        }
    }
}

#block-views-homepage-banner-block {
    .views-row {
        padding: 20px;
        background-position: center top;
        background-size: cover;
        @include breakpoint($mobile-only--tiny) {
            background-position: 60% top;
        }
    }
    .views-field-field-banner-image {
        display: none;
    }
    .button-blue {
        margin: 10px 10px 10px 0;
        @include breakpoint($tablet) {
            margin: 20px 20px 20px 0;
        }
    }
    h1 {
        color: white;
        text-shadow: #252525 1px 1px 12px;
        @include breakpoint($desktop) {
            font-size: 48px;
            line-height: 48px;
        }
    }
    .views-field-body {
        color: white;
        max-width: 660px;
        text-shadow: #252525 1px 1px 12px;
        h4 {
            line-height: 1.2em;
            font-weight: 500;
        }
    }
    .views-field-field-buttons {

    }
    @include breakpoint($tablet) {
        h1 {
            margin: 80px 0 20px 0;
        }
    }
    @include breakpoint($desktop) {
        h1 {
            margin: 120px 0 20px 0;
        }
    }
}
