@mixin container {
    max-width: $container;
    margin: 0 auto;
}

@mixin plain-list {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        list-style-type: none;
        list-style-image: none;
    }
}

@mixin button {
    padding: 15px;
    display: inline-block;
    background: $grey-mid;
    border: 0px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    transition: 0.3s ease all;
    font-size: 14px;
    font-family: $font-family;
    &:hover {
        background: $blue;
    }
}
.button {
    @include button;
}
@mixin button-blue {
    padding: 15px;
    display: inline-block;
    background: $blue;
    border: 0px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    transition: 0.3s ease all;
    font-size: 14px;
    font-family: $font-family;
    &:hover {
        background: lighten($blue, 15%);
        text-decoration: none;
        color: white;
    }
}
.button-blue {
    @include button-blue;
}

@mixin button--small {
    padding: 3px 6px;
    display: inline-block;
    background: $grey-light;
    border: 0px;
    color: $grey-mid;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    transition: 0.3s ease all;
    font-size: 12px;
    font-family: $font-family;
    &:hover {
        background: $grey-mid;
        color: $grey-lightlight;
        text-decoration: none;
    }
}

@mixin shadow {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.09);
}

@mixin transition {
    transition: 0.3s ease all;
}

// ------------------- VIEWS ----------------------
// -------------------   2   ----------------------

@mixin view--two-column {
    .view-content {
        display: flex;
        flex-wrap: wrap;
        .views-row {
            margin-bottom: $spacing;
            flex: 1 1 100%;
        }
        @include breakpoint($tablet) {
            .views-row {
                flex: 0 1 calc(50% - 20px);
                -ms-flex: 0 1 46%;
                margin-right: $spacing;
            }
        }
    }
}
.view--two-column {
    @include view--two-column;
}
// -------------------   3   ----------------------
@mixin view--three-column {
    .view-content {
        display: flex;
        flex-wrap: wrap;
        .views-row {
            margin-bottom: $spacing;
            flex: 1 1 100%;
        }
        @include breakpoint($tablet) {
            .views-row {
                flex: 0 1 calc(50% - 20px);
                -ms-flex: 0 1 46%;
                margin-right: $spacing;
            }
        }
        @include breakpoint($desktop) {
            .views-row {
                flex: 0 1 calc(33.33% - 20px);
                -ms-flex: 0 1 31%;
            }
        }
    }
}
.view--three-column {
    @include view--three-column;
}
// -------------------   4   ----------------------
@mixin view--four-column {
    .view-content {
        display: flex;
        flex-wrap: wrap;
        .views-row {
            margin-bottom: $spacing;
            flex: 1 1 100%;
        }
        @include breakpoint($tablet) {
            .views-row {
                flex: 0 1 calc(50% - 20px);
                -ms-flex: 0 1 46%;
                margin-right: $spacing;
            }
        }
        @include breakpoint($desktop) {
            .views-row {
                flex: 0 1 calc(25% - 20px);
                -ms-flex: 0 1 21%;
            }
        }
    }
}
.view--four-column {
    @include view--four-column;
}
