.page-breed-profiles {
    .l-content--page-info {
        > h1.page-title {
            // display: none;
        }
    }
}

#block-views-exp-breed-profiles-page {
    .views-exposed-form .views-exposed-widgets .views-exposed-widget {
        margin-bottom: 0px;
        padding-bottom: 0px;
        border-bottom: 0px;
    }
}

.vocabulary-cat-breeds.view-mode-teaser,
.vocabulary-dog-breeds.view-mode-teaser {
    border: 1px solid $grey-light;
    @include transition;
    &:hover {
        @include shadow;
        background: $blue;
        color: white;
        .field--name-title a {
            color: white !important;
            &:hover {
                color: white !important;
            }
        }
        .field--type-image {
            a:after {
                opacity: 0.4;
            }
        }
    }
    .field {
        margin: 0 15px;
    }
    .field--type-image {
        margin: 0;
        position: relative;
        a {
            display: block;
            &:after {
                content: '';
                height: 100%;
                width: 100%;
                background: $blue;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                @include transition;
            }
        }
    }
}

.vocabulary-cat-breeds.view-mode-full,
.vocabulary-dog-breeds.view-mode-full {
    .group-left {
        @include breakpoint($tablet) {
            padding-right: 20px;
        }
    }
    @include breakpoint($mobile) {
        .group-left,
        .group-right {
            width: 100%;
            float: none;
        }
    }
}
